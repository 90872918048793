import React, { useState, useEffect } from 'react';
import sanityClient from '../client'

import {
    Row,
    Container,
    CardDeck,
    Col
} from 'reactstrap'
import StoryTogether from '../components/storyblobtogether'

const Properties = props => {
    const [Stories, setStories] = useState();

    useEffect(() => {
        const getStories = async () => {
            const QueryString = `*[_type=="page" && title=="Properties"]{title, stories[]->{Title, blobs[]->{Title, content, "Image": image.asset->url}}}[0]`;

            let Data = await sanityClient.fetch(
                QueryString,
                {});
        
            // Data = [{StoryTitle1, Array of blobs},{StoryTitle N, Array of blobs N}] 
            //  -> [[Array of Blobs for StoryTitle (Where blob has storyTitle as item)]] 
            Data = Data.stories.map(q => q.blobs.map(v => ({ ...v, storyTitle: q.Title })))
       
            setStories(Data);
        };
        getStories();
    }, []);


    //  {Stories && Stories[0].map((item, index) => <StoryLargeBlob id={index} {...item} />)}
    return (
        <Container fluid={true}>
            <Row width="100" className="p-5"></Row>
            <Row width="100" className="p-5" >
                <Col sm="2"></Col>
                <Col sm="8" className="align-items-center pt-5">
                    <CardDeck>
                        {Stories && Stories[0].map((item, index) => <StoryTogether id={index} {...item} />)}
                    </CardDeck>
                </Col>
                <Col sm="2"></Col>
            </Row>
            <Row width="100" className="p-5"></Row>
            <Row width="100" className="p-5"></Row>

        </Container>
    )
}

export default Properties
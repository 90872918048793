import React from 'react'
import sanityClient from '../client'
import imageUrlBuilder from '@sanity/image-url'
import Serializers from './serializers'
import {
    Row,
    Container,
    Col,

} from 'reactstrap'
import BlockContent from '@sanity/block-content-to-react'

const builder = imageUrlBuilder(sanityClient)

function isEven(n) {
    return n % 2 === 0;
}

function urlFor(source) {
    return builder.image(source)
  }

const StoryBlob = (props) => {
    const {id,  storyTitle, Title, content, Image} = props

    return (
        
        <Container fluid={true}>
            <Row width="100"><Col className="p-5"></Col></Row>
            { !isEven(id) && <Col sm="2"></Col>}
            <Row className="h-100 pb-5 justify-content-center align-items-center" >
                <Col sm={isEven(id) ? "3" : "5"} className="pr-5 pt-5 text-left v-100">
                    {isEven(id) && < img top width="80%" className="align-items-left" src={urlFor(Image).url()} alt="keys" />}
                    {!isEven(id) && <div><h6 className="pb-4">{storyTitle}</h6><h3>{Title}</h3>
                    <hr/><p><BlockContent blocks={content} serializers={Serializers} /></p></div>}
                    
                </Col>
                <Col sm={!isEven(id) ? "3" : "5"} className="pr-5 pt-5 text-right v-100">
                    {!isEven(id) && < img top width="80%" className="align-items-right" src={urlFor(Image).url()} alt="keys" />}
                    {isEven(id) && <div><h6 className="pb-4 text-left">{storyTitle}</h6><h3 class="text-left">{Title}</h3>
                    <hr/><p class="text-left"><BlockContent blocks={content} serializers={Serializers} /></p></div>}
                </Col>
            </Row>
            { isEven(id) && <Col sm="2"></Col>}
        </Container>)

}

export default StoryBlob
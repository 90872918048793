import React from 'react'
import sanityClient from '../client'
import imageUrlBuilder from '@sanity/image-url'
import BlockContent from '@sanity/block-content-to-react'
import Serializers from './serializers'
import {

    Card,
    CardImg,
    CardBody,
    CardTitle,
    CardText

} from 'reactstrap'

const builder = imageUrlBuilder(sanityClient)

function urlFor(source) {
    return builder.image(source)
}

const CardImageTop = {
    "width": "100%",
    "height": "15vw",
    "object-fit": "cover",
}

const StoryTogether = (props) => {
    const { Title, content, Image } = props



    return (
        <Card>
            <CardImg top style={CardImageTop} width="100%" src={urlFor(Image).height(500).url()} alt="student" />
            <CardBody>
                <CardTitle><h4>{Title}</h4></CardTitle>
                <CardText><BlockContent blocks={content} serializers={Serializers} /></CardText>
            </CardBody>
        </Card>

    )

}

export default StoryTogether

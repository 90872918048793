import React, { useState, useEffect } from 'react';
import Logo from './logo'
import sanityClient from '../client'

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,

} from 'reactstrap';


const NavMenu = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const [Pages, setPages] = useState()

  useEffect(() => {
    const getPages = async () => {
      const QueryString = `*[_type=="page"]{title, positionnumber, link}| order(positionnumber asc)`;

      let Data = await sanityClient.fetch(
        QueryString,
        {});

      setPages(Data);
    };
    getPages();
  }, []);

  const NavItemRender = (Item) => {
    return (<NavItem>
      <NavLink href={Item.link}>{Item.title}</NavLink>
    </NavItem>)
  }

  return (
    <div>
      <Navbar dark="true" expand="lg" fixed="top">

        <NavbarBrand href="/" ><Logo /></NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar >
            {Pages && Pages.map(item => NavItemRender(item))}

          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
}

export default NavMenu;

import React, {useState, useEffect} from 'react';
import sanityClient from '../client'

import {
    Row,
    Container,


} from 'reactstrap'
import StoryLargeBlob from '../components/storylargeblob'

const AboutUs = props => {
    const [Stories, setStories] = useState();

    useEffect(() => {
        const getStories = async () => {
            const QueryString = `*[_type=="page" && title=="About Us"]{title, stories[]->{Title, blobs[]->{Title, content, "Image": image.asset->url}}}[0]`;

            let Data = await sanityClient.fetch(
                QueryString,
                {});
            // Data = [{StoryTitle1, Array of blobs},{StoryTitle N, Array of blobs N}] 
            //  -> [[Array of Blobs for StoryTitle (Where blob has storyTitle as item)]] 
            Data = Data.stories.map(q => q.blobs.map(v => ({ ...v, storyTitle: q.Title })))
            console.log(Data);
            setStories(Data);
        };
        getStories();
    }, []);

    const BackGround = {
        width: "auto",
        backgroundColor: "#A2D5F2"
    };
//  {Stories && Stories[0].map((item, index) => <StoryLargeBlob id={index} {...item} />)}
    return(
        <Container fluid={true}>
             <Row width="100" className="p-5" style={BackGround}>
             {Stories && Stories[0].map((item, index) => <StoryLargeBlob id={index} {...item} />)}
             </Row>
        </Container>
    )
}

export default AboutUs
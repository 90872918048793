import React, { useState, useEffect } from 'react';
import sanityClient from '../client'
import Login from '../components/login'
import {
    Row,
    Container,

    CardDeck,
    Col
} from 'reactstrap'


const Support = props => {
    const [Stories, setStories] = useState();
    const BackGround = {
        width: "auto",
        backgroundColor: "#A2D5F2"
    };
    useEffect(() => {
        const getStories = async () => {
            const QueryString = `*[_type=="page" && title=="Help & Support"]{title, stories[]->{Title, blobs[]->{Title, content, "Image": image.asset->url}}}[0]`;

            let Data = await sanityClient.fetch(
                QueryString,
                {});
            console.log(Data)
            // Data = [{StoryTitle1, Array of blobs},{StoryTitle N, Array of blobs N}] 
            //  -> [[Array of Blobs for StoryTitle (Where blob has storyTitle as item)]] 
            Data = Data.stories.map(q => q.blobs.map(v => ({ ...v, storyTitle: q.Title })))

            setStories(Data);
        };
        getStories();
    }, []);



    return (
        <Container style={BackGround} fluid={true}>
            <Row width="100" className="p-5"></Row>
            <Row width="100" className="p-5" >
                <Col sm="2"></Col>
                <Col sm="8" className="align-items-center pt-5">

                    <CardDeck>
                        {Stories && Stories[0].map((item, index) => <Login id={index} {...item} />)}

                    </CardDeck>

                </Col>

                <Col sm="2"></Col>
            </Row>
            <Row width="100" className="p-5"></Row>
            <Row width="100" className="p-5"></Row>

        </Container>
    )
}

export default Support
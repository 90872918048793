import React, { useState, useEffect } from 'react';
import {
    Row,
    Container,
    Col,

} from 'reactstrap'

import sanityClient from '../client'
import desktopImage from '../images/robert-thomas.jpg'
import phoneImage from '../images/robert-thomas-phone.jpg'

import StoryBlob from '../components/storyBlob'

const Home = props => {

    const [Stories, setStories] = useState();


    useEffect(() => {
        const getStories = async () => {
            const QueryString = `*[_type=="page" && title=="Home"][0]{title, stories[]->{Title, blobs[]->{Title, content, "Image": image.asset->url}}}`;
            

            let Data = await sanityClient.fetch(
                QueryString,
                {});
            // Data = [{StoryTitle1, Array of blobs},{StoryTitle N, Array of blobs N}] 
            //  -> [[Array of Blobs for StoryTitle (Where blob has storyTitle as item)]] 
            Data = Data.stories.map(q => q.blobs.map(v => ({ ...v, storyTitle: q.Title })))
            console.log(Data);
            setStories(Data);
        };


        getStories();
    }, []);

    const useWindowWidth = () => {
        const [windowWidth, setWindowWidth] = useState(window.innerWidth);

        useEffect(() => {
            const handleWindowResize = () => {
                setWindowWidth(window.innerWidth);
            };

            window.addEventListener('resize', handleWindowResize);
            return () => window.removeEventListener('resize', handleWindowResize);
        }, []);

        return windowWidth;
    };

    const ig = useWindowWidth() >= 650 ? desktopImage : phoneImage;
    
    const LandingBackGround = {
        width: "auto",
        height: "100vh",
        backgroundImage: `url(${ig})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: "100% 100%"
    };

    const BottomBackGround = {
        width: "auto",
        backgroundColor: "#A2D5F2"
    };

    

    return (
        <Container fluid={true}>

            <Row width="100" >
                <Col style={LandingBackGround}>
                    <span className="landing_text">Providing Rented Homes <br />in the East Midlands</span>
                </Col>
            </Row>


            <Row width="100" className="pb-5">{Stories && Stories[0].map((item, index) => <StoryBlob id={index} {...item} />)}</Row>

            <Row width="100" className="pb-5" style={BottomBackGround}>{Stories && Stories[1].map((item, index) => <StoryBlob id={index} {...item} />)}</Row>



        </Container>

    );
};


export default Home;

import React, { useState, useEffect } from 'react';
import { Container, Row, Col, ListGroup, ListGroupItem } from 'reactstrap';
import sanityClient from '../client'


const Footer = props => {

    const [Data, setData] = useState();
    const [Pages, setPages] = useState()


    useEffect(() => {
        const getData = async () => {
            let Data = await sanityClient.fetch(
                '*[_type=="contact" && name=="Castle (Donington) Developments Ltd"]{name, location, phone, email}[0]',
                {});

            setData(Data);
            const QueryString = `*[_type=="page"]{title, positionnumber, link}| order(positionnumber asc)`;
            Data = await sanityClient.fetch(
                QueryString,
                {});

            setPages(Data);

        };
        getData();
    }, []);

    const ListItemRender = (Item) => {
        return (<ListGroupItem className="bg-info border border-info" >
            <a className='text-white' href={Item.link}>{Item.title}</a>
        </ListGroupItem>)
    }



    return (

        <Container fluid={true} className="bg-info">
            <Row width="100">
                <Col sm="2">  </Col>
                <Col sm="8" className=" m-0 p-0  text-white text-center">
                    <Row width="100">
                        <Col sm="4" className="p-3 justify-content-center align-items-center">
                            <h5>Navigation</h5>
                            <ListGroup>
                                {Pages && Pages.map(Item => ListItemRender(Item))}
                            </ListGroup>
                        </Col>
                        <Col sm='8' className="p-3 text-align-right">
                            <div className="pt-5" >{Data && Data.location}</div>
                            <div >{Data && Data.phone}</div>
                            <div >{Data && Data.email}</div>
                        </Col>
                    </Row>
                </Col>
                <Col sm="2"  ></Col>
            </Row>
            <Row width="100" className="bg-light text-white justify-content-center py-3">
                <p className="text-muted font-weight-light">&copy; {new Date().getFullYear()} Copyright:&nbsp;</p>  <a className='text-muted' href="/"> Castle (Donington) Devlopments Ltd.</a>
            </Row>
        </Container >
    )
}

export default Footer


import React, { useState, } from 'react';
import sanityClient from '../client'
import {
    Button, Form, FormGroup, Label, Input,
    Row,
    Container,
    Col, FormText
} from 'reactstrap';
import BlockContent from '@sanity/block-content-to-react'
import Serializers from './serializers'
import imageUrlBuilder from '@sanity/image-url'

const builder = imageUrlBuilder(sanityClient)

function urlFor(source) {
    return builder.image(source)
}



function Login(props) {
    const {Title, content, Image } = props;
    const [Creds, setCreds] = useState({
        email: "",
        password: "",
        emailState: "",
    });


    const validateEmail = (e) => {
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const isValid = emailRex.test(e.target.value) ? 'has-success' : 'has-danger'

        setCreds(prevState => ({
            ...prevState,
            "emailState": isValid

        }))

    }

    const handleChange = (e) => {
        const { id, value } = e.target

        setCreds(prevState => ({
            ...prevState,
            [id]: value,

        }))
    }
    return (
        <Container fluid={true}>
            <Row>
                <Col sm="2">

                </Col>
                <Col className="vertical-align middle" sm="3">
                    < img width="100%" className="align-items-left" src={urlFor(Image).height(700).url()} alt="keys" />
                </Col>
                <Col sm="1">

                </Col>
                <Col sm="3">
                    <h2 class="text-left">{Title}</h2>
                    <hr />
                    <h6><BlockContent blocks={content} serializers={Serializers} /></h6>
                    <Form className="form p-2">
                        <FormGroup row>
                            <Label>Username</Label>
                            <Input type="email" 
                            name="email" 
                            id="Email" 
                            placeholder="Username" 
                            valid={ Creds.emailState === 'has-success' }
                            invalid={ Creds.emailState === 'has-danger' }
                            onChange={(e) => {
                                validateEmail(e)
                                handleChange(e)
                            }} />

                        </FormGroup>
                        <FormGroup row>
                            <Label for="Password">Password</Label>
                            <Input type="password" name="password" id="Password" placeholder="***********" />
                        </FormGroup>
                        <FormGroup row>
                        <Button>Submit</Button>
                        <hr />
                        <FormText><a href="/">Forgotten password?</a></FormText>
                        </FormGroup>
                    </Form>
                </Col>
                <Col sm="2">

                </Col>
            </Row>

        </Container>


    )
}

export default Login;
import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const Logo = () => {
    return (
        <Container fluid={true} className="bg-info">
        <Row className="mt-2">
            <Col sm="6" className="m-0 p-0 text-white text-center"><h1 class="display-5">C</h1> </Col>
            <Col sm="6" className=" m-0 p-0  text-white text-center"><h1 class="display-5">D</h1></Col>
            <Col sm="12" className="m-0 p-0 border border-info bg-info text-white text-center" >
                <h6>DEVELOPMENTS</h6>
            </Col>
        </Row>
    </Container >
    );
}

export default Logo
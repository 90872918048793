import React from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import NavMenu from './components/NavBar'
import Home from './views/home'
import Footer from './components/footer'
import AboutUs from './views/aboutus'
import Properties from './views/properties'
import Support from './views/support'
import Contact from './views/contactus'

function App() {
  return (
    <Router>
      <NavMenu />
      <Switch>
      <Route path="/support">
          <Support />
        </Route>
        <Route path="/about">
          <AboutUs />
        </Route>
        <Route path="/properties">
          <Properties />
        </Route>
        <Route path="/contactus">
          <Contact />
        </Route>
        <Route path="/">
         <Home/>
        </Route>
      </Switch>
     <Footer></Footer>
    </Router>

    /* test area */

  )
}

export default App;

